<template>
  <div class="bar-container">
    <vue-c3 ref="bar-chart" :handler="handler" class="inc-canvas"></vue-c3>
  </div>
</template>

<script>
import * as d3 from 'd3';
import Vue from 'vue';
import VueC3 from 'vue-c3';
import { isBlank } from 'adready-api/helpers/common';
import defaultTheme from '@/plugins/Theme/defaultTheme';
import { formatNumberAsLargeNumber } from '~/util/utility-functions';

export default {
  name: 'HorizontalBarChart',
  components: {
    VueC3,
  },
  props: {
    columns: {
      type: Array,
      required: false,
      default: () => [],
    },
    tooltip: {
      type: Array,
      required: false,
      default: () => [],
    },
    xAxisLabelsMultiline: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    xAxisLabelFormat: {
      type: String,
      required: false,
      default: () => '',
    },
    chartHeight: {
      type: Number,
      required: false,
      default: () => 240,
    },
    barWidth: {
      type: Number,
      required: false,
      default: () => 50,
    },
    isBarWidthRatio: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data: () => {
    return {
      handler: new Vue(),
    };
  },
  computed: {
    options() {
      const vm = this;
      const allowMultilineTick = vm.xAxisLabelsMultiline;
      const barConfig = {};
      if (vm.isBarWidthRatio) {
        barConfig.width = {};
        barConfig.width.ratio = vm.barWidth;
      } else {
        barConfig.width = vm.barWidth;
      }

      return {
        data: {
          type: 'bar',
          x: 'x',
          columns: [],
          labels: {
            format(v) {
              return formatNumberAsLargeNumber(v, vm.xAxisLabelFormat);
            },
          },
        },
        color: {
          pattern: [defaultTheme.primaryColor, '#abb2c5'],
        },
        size: {
          height:
            vm._props.columns[0]?.length > 10
              ? vm.chartHeight + 800 + vm._props.columns[0]?.length * 30
              : vm.chartHeight + 150,
          width: 760,
        },
        tooltip: {
          contents(d = []) {
            if (isBlank(vm.tooltip) && !vm.tooltip.length) {
              return '';
            }

            let tooltipBody = `<div class='ctooltip'>`;

            const tooltipRows = vm.tooltip[d[0].index];
            for (let i = 0; i < tooltipRows.length; i++) {
              const { rowCss, columns } = tooltipRows[i];
              tooltipBody = tooltipBody.concat(`<div class='${rowCss}'>`);
              if (columns) {
                for (let j = 0; j < columns.length; j++) {
                  const { css, value } = columns[j];
                  tooltipBody = tooltipBody.concat(`<div class='${css}'>${value}</div>`);
                }
              }
              tooltipBody = tooltipBody.concat('</div>');
            }

            tooltipBody = tooltipBody.concat('</div>');
            return tooltipBody;
          },
        },
        axis: {
          x: {
            show: true,
            type: 'category',
            anchor: 'left',
            tick: {
              multiline: allowMultilineTick,
              multilineMax: vm._props.columns[0]?.length > 12 ? 1 : 3,
            },
          },
          y: {
            tick: {
              format(d) {
                return formatNumberAsLargeNumber(d, vm.xAxisLabelFormat);
              },
            },
          },
          rotated: true,
        },
        onrendered() {
          const columns = vm._props.columns[0];
          d3.selectAll('.c3-axis-x .tick text').each(function(d) {
            let title = d3.select(this).select('title');
            if (title.empty()) {
              title = d3.select(this).append('title');
            }
            title.text(columns[d + 1]);
          });
        },
        bar: barConfig,
      };
    },
  },
  watch: {
    columns() {
      const vm = this;

      vm.render();
    },
  },
  mounted() {
    const vm = this;

    vm.render();
  },
  beforeDestroy() {
    const vm = this;

    vm.handler.$emit('destroy');
  },
  methods: {
    render() {
      const vm = this;

      vm.handler.$emit('destroy');

      vm.handler.$emit('init', vm.options);

      vm.handler.$emit('dispatch', (chart) => {
        chart.load({
          columns: vm.columns,
        });
        const fontSize = vm.xAxisLabelsMultiline ? '11px' : '13px';
        d3.selectAll('.bar-container .c3-axis.c3-axis-x .tick text').style('font-size', fontSize);
      });
    },
  },
};
</script>

<style lang="scss">
.bar-container {
  position: relative;
  tspan {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  svg {
    width: 100%;
  }
  table {
    border-collapse: inherit !important;
  }
  .c3 text {
    fill: #7e8890;
    font-size: 12px;
  }
  .c3-axis-x text {
    font-size: 13px;
    max-width: 200px;
  }
  .c3-legend-item {
    font-size: 12px;
  }
  .c3-legend-item text {
    font-size: 13px;
  }
  .bar-chart {
    width: 100%;
    height: 100%;
  }
  .c3-chart-arc path {
    stroke: rgba(0, 0, 0, 0.1);
  }
  .c3 line,
  .c3 path {
    stroke: rgba(180, 180, 180, 0.2);
  }

  .c3-tooltip-container {
    border-radius: 4px;
    overflow: visible !important;
  }
  .c3-tooltip {
    background-color: transparent;
    box-shadow: none;
    margin-top: -37px;
    opacity: 0.9;
  }

  .c3-tooltip td {
    padding: 10px;
    font-size: 11px;
    color: #999;
    background-color: #222;
    border-left: 0px dotted #999;
  }

  .c3-tooltip td > span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 6px;
  }
  .c3-tooltip td.value {
    font-size: 12px;
    font-weight: 300;
    border-left: 1px dotted rgba(130, 130, 140, 0.2);
  }
  .c3-tooltip tr {
    border: 0px solid #ccc;
  }

  .ctip-div {
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 8px;
  }

  .ctip-name {
    float: left;
    width: 100%;
    text-align: left;
  }

  .ctip-val {
    display: inline-block;
    float: right;
    font-size: 13px;
  }

  .ctip-head-wrap {
    color: #fff;
    font-size: 12px;
    line-height: 1.3em;
    display: flex;
    font-family: 'Source Sans Pro', sans-serif;
  }

  .ctip-dif-wrap,
  .ctip-row-wrap {
    color: #abb2c5;
    display: flex;
    width: 100%;
  }

  .ctip-row-wrap.exp {
    color: #fff;
    display: flex;
    width: 100%;
  }

  .ctip-positive {
    color: var(--primarygreen);
    display: inline-block;
    float: right;
  }

  .ctip-negative {
    color: var(--primaryred);
    display: inline-block;
    float: right;
  }

  .ctip-dif-name,
  .ctip-row-name {
    font-size: 13px;
    vertical-align: top;
    width: 100%;
    text-align: left;
    font-family: 'Source Sans Pro', sans-serif;
  }

  .ctip-dif,
  .ctip-row-val {
    float: right;
    font-size: 13px;
    vertical-align: top;
  }

  .ctip-head-wrap .ctip-dif {
    font-size: 13px;
    font-family: 'Source Sans Pro', sans-serif;
  }
}
</style>

<!-- <style lang="scss" scoped>
.bar-container .flip {
  .c3-tooltip-container {
    border-radius: 4px;
    overflow: auto;
  }
  .c3-tooltip {
    width: auto;
    background-color: transparent;
    box-shadow: none;
    margin-top: -37px;
    opacity: 0.9;
  }
}
</style> -->
